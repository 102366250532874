/**
 * CAUTION: This file is automatically generated!
 */
import { ErrorHandler, Injectable, Type } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ApiConfiguration } from './configuration';
import { Observable, throwError } from 'rxjs';

interface HttpRequestOptions {
    body?: any;
    headers?: { [header: string]: string | string[]; };
}

interface ApiRequestContext {
    traceStart: number;
}

export interface ApiRequest {
    method: string;
    path: string;
    query: Record<string, string>;
    headers: Record<string, string|string[]>;
    body: any;
}

export type RequestPerformer =
    (
        method: string,
        path: string,
        query: Record<string, string>,
        headers: Record<string, string|string[]>,
        body: any
    ) => Observable<any>
;

export class ApiResponseError extends Error {
    constructor(constructor: Type<ApiResponseError> & typeof ApiResponseError, request: ApiRequest, response: HttpErrorResponse, requestStack: string) {
        super(`${constructor.typeName}: ${response.status === 0 ? 'Failed to connect' : response.status} during ${request.method} ${request.path}${response.error?.message ? `, server said: ${response.error.message}` : ``}`);

        this.$request = request;
        this.$response = response;
        this.$requestStack = requestStack;
        this.$description = this.message;

        let params = { ...response.error };
        delete params.message;
        Object.assign(this, params);
    }

    $request: ApiRequest;
    $response: HttpErrorResponse;
    $requestStack: string;
    $description: string;

    get $http() { return this.$response; }

    /**
     * Generate a custom stack trace for this error. By default, uses the request and response synchronous stacks and includes
     * the raw response. More specific error classes may override this.
     *
     * @param innerStack
     * @param outerStack
     * @returns
     */
    generateCustomStack(innerStack: string, outerStack: string) {
        return `${this.message}\n\n`
            + `Response:\n`
            + `${JSON.stringify(this.$response.error, undefined, 4)}\n`
            + `\n`
            + `Stack:`
            + `\n`
            + `${outerStack}`
            + `\n`
            + `-- response stack --\n`
            + `${innerStack.split("\n").slice(1).join("\n")}`
        ;

    }

    /**
     * Legacy, for code which expected the response itself, not the error object.
     * @returns
     */
    json() { return this; }

    static types = new Map<string, Type<ApiResponseError>>();
    static for(request: ApiRequest, response: HttpErrorResponse, outerStack: string) {

        let stackFrames = outerStack.split("\n");
        let firstRelevantStackFrame = stackFrames.findIndex(x => /[ \t]+at (.*)Api\./.test(x));
        if (firstRelevantStackFrame >= 0) {
            stackFrames = stackFrames.slice(firstRelevantStackFrame);
            outerStack = stackFrames.join("\n");
        }


        let klass = this.types.get(`${response.status}`) ?? this.types.get(`${response.status / 100 | 0}xx`) ?? ApiResponseError;
        let error = new klass(klass, request, response, outerStack);

        error.stack = error.generateCustomStack(error.stack, outerStack);

        if (response.error?.message) {
            error.message = response.error.message;
        } else if (response.error?.error) {
            error.message = response.error.error;
        }

        return error;
    }

    static typeName = 'ApiResponseError';
}

function ErrorStatus(code: string, typeName: string) {
    return (target: Type<ApiResponseError> & typeof ApiResponseError) => {
        ApiResponseError.types.set(code, target);
        target.typeName = typeName;
    };
}

@ErrorStatus('0', 'ApiConnectionError') export class ApiConnectionError extends ApiResponseError {
    generateCustomStack(innerStack: string, outerStack: string): string {
        return this.message; // Stack trace is irrelevant.
    }
}

// Client errors (4xx)
@ErrorStatus('4xx', 'ApiClientError') export class ApiClientError extends ApiResponseError {}
@ErrorStatus('400', 'ApiBadRequestError') export class ApiBadRequestError extends ApiClientError {}
@ErrorStatus('401', 'ApiUnauthorizedError') export class ApiUnauthorizedError extends ApiClientError {}
@ErrorStatus('402', 'ApiPaymentRequiredError') export class ApiPaymentRequiredError extends ApiClientError {}
@ErrorStatus('403', 'ApiForbiddenError') export class ApiForbiddenError extends ApiClientError {}
@ErrorStatus('404', 'ApiNotFoundError') export class ApiNotFoundError extends ApiClientError {}
@ErrorStatus('405', 'ApiMethodNotAllowedError') export class ApiMethodNotAllowedError extends ApiClientError {}
@ErrorStatus('406', 'ApiNotAcceptableError') export class ApiNotAcceptableError extends ApiClientError {}
@ErrorStatus('408', 'ApiRequestTimeoutError') export class ApiRequestTimeoutError extends ApiClientError {}
@ErrorStatus('409', 'ApiConflictError') export class ApiConflictError extends ApiClientError {}
@ErrorStatus('410', 'ApiGoneError') export class ApiGoneError extends ApiClientError {}
@ErrorStatus('411', 'ApiLengthRequiredError') export class ApiLengthRequiredError extends ApiClientError {}
@ErrorStatus('412', 'ApiPreconditionFailedError') export class ApiPreconditionFailedError extends ApiClientError {}
@ErrorStatus('413', 'ApiPayloadTooLargeError') export class ApiPayloadTooLargeError extends ApiClientError {}
@ErrorStatus('414', 'ApiRequestURITooLongError') export class ApiRequestURITooLongError extends ApiClientError {}
@ErrorStatus('415', 'ApiUnsupportedMediaTypeError') export class ApiUnsupportedMediaTypeError extends ApiClientError {}
@ErrorStatus('416', 'ApiRequestRangeNotSatisfiableError') export class ApiRequestRangeNotSatisfiableError extends ApiClientError {}
@ErrorStatus('417', 'ApiExpectationFailedError') export class ApiExpectationFailedError extends ApiClientError {}
@ErrorStatus('418', 'ApiImATeapotError') export class ApiImATeapotError extends ApiClientError {}
@ErrorStatus('420', 'ApiEnhanceYourCalmError') export class ApiEnhanceYourCalmError extends ApiClientError {}
@ErrorStatus('421', 'ApiMisdirectedRequestError') export class ApiMisdirectedRequestError extends ApiClientError {}
@ErrorStatus('422', 'ApiUnprocessableEntityError') export class ApiUnprocessableEntityError extends ApiClientError {}
@ErrorStatus('423', 'ApiLockedError') export class ApiLockedError extends ApiClientError {}
@ErrorStatus('424', 'ApiFailedDependencyError') export class ApiFailedDependencyError extends ApiClientError {}
@ErrorStatus('425', 'ApiTooEarlyError') export class ApiTooEarlyError extends ApiClientError {}
@ErrorStatus('426', 'ApiUpgradeRequiredError') export class ApiUpgradeRequiredError extends ApiClientError {}
@ErrorStatus('428', 'ApiPreconditionRequiredError') export class ApiPreconditionRequiredError extends ApiClientError {}
@ErrorStatus('429', 'ApiTooManyRequestsError') export class ApiTooManyRequestsError extends ApiClientError {}
@ErrorStatus('431', 'ApiRequestHeaderFieldsTooLargeError') export class ApiRequestHeaderFieldsTooLargeError extends ApiClientError {}
@ErrorStatus('444', 'ApiNoResponseError') export class ApiNoResponseError extends ApiClientError {}
@ErrorStatus('450', 'ApiBlockedByWindowsParentalControlsError') export class ApiBlockedByWindowsParentalControlsError extends ApiClientError {}
@ErrorStatus('451', 'ApiUnavailableForLegalReasonsError') export class ApiUnavailableForLegalReasonsError extends ApiClientError {}
@ErrorStatus('497', 'ApiHTTPRequestSentToHTTPSPortError') export class ApiHTTPRequestSentToHTTPSPortError extends ApiClientError {}
@ErrorStatus('498', 'ApiTokenExpiredError') export class ApiTokenExpiredError extends ApiClientError {}
@ErrorStatus('499', 'ApiClientClosedRequestError') export class ApiClientClosedRequestError extends ApiClientError {}

// Server errors (5xx)
@ErrorStatus('5xx', 'ApiServerError') export class ApiServerError extends ApiResponseError {}
@ErrorStatus('501', 'ApiNotImplementedError') export class ApiNotImplementedError extends ApiServerError {}
@ErrorStatus('502', 'ApiBadGatewayError') export class ApiBadGatewayError extends ApiServerError {}
@ErrorStatus('503', 'ApiServiceUnavailableError') export class ApiServiceUnavailableError extends ApiServerError {}
@ErrorStatus('504', 'ApiGatewayTimeoutError') export class ApiGatewayTimeoutError extends ApiServerError {}

@Injectable()
export class Requester {
    constructor(
        protected http: HttpClient,
        protected configuration: ApiConfiguration
    ) {

    }

    /**
     * Perform an API request. Called by generated APIs.
     */
    request<T>(
        method: string,
        path: string,
        query: Record<string, string>,
        headers: Record<string, string|string[]>,
        body?: any
    ): Observable<T> {
        return <Observable<T>> this.requestPerformer(method, path, query, headers, body);
    }

    /**
     * Defines how requests are performed by the API layer. Can be overridden to modify this behavior.
     */
    requestPerformer: RequestPerformer = (method, path, query, headers, body) =>
        this.performRequest(method, path, query, headers, body);

    logInfo = (message: string) => console.log(message);
    logWarning = (message: string) => console.warn(message);
    logError = (message: string) => console.error(message);

    /**
     * Default request performer. Can be called from a custom request performer to act as a middleware for
     * the normal API request procedure.
     *
     * @param method
     * @param path
     * @param query
     * @param headers
     * @param body
     * @returns
     */
    public performRequest<T>(
        method: string,
        path: string,
        query: Record<string, string>,
        headers: Record<string, string|string[]>,
        body?: any
    ) {
        let traceStart = Date.now();
        query = { ...this.configuration.globalQueryParams, ...query };
        path = `${this.configuration.basePath}${path}`;

        if (Object.keys(query).length > 0) {
            path = `${path}?${Object.keys(query)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`).join('&')}`;
        }

        let authState = this.configuration.accessToken ? `(authenticated) ` : ``;

        headers ??= {};

        this.applyGlobalHeaders(headers);

        if (body !== undefined)
            headers['Content-Type'] = 'application/json';

        let outerStack = new Error('').stack!.split("\n").slice(1).join("\n");
        let request = this.http
            .request<T>(method, path, {
                observe: 'response',
                responseType: 'json',
                withCredentials: this.configuration.withCredentials,
                headers,
                body: body == null ? null : JSON.stringify(body),
            })
            .pipe(
                catchError(e => {
                    let time = Date.now() - traceStart;

                    if (e instanceof HttpErrorResponse) {
                        this.logError(`[API] ${method} ${authState}${path} (${time} ms): Error: ${e.status} ${e.statusText}`);
                        return throwError(ApiResponseError.for({ method, path, body, headers, query }, e, outerStack));
                    }

                    return throwError(e);
                }),
                map(response => {
                    let time = Date.now() - traceStart;
                    this.logInfo(`[API] ${method} ${authState}${path} (${time} ms): Finished: ${response.status} ${response.statusText}`);

                    return response;
                })
            )
        ;

        return this.prepareResult(request);
    }

    private prepareResult<T>(result : Observable<HttpResponse<T>>) {
        return result.pipe(
            catchError(e => { // e is the response body object
                if (e.status === 0 || e.status === 500 || e.status === 504) {
                    return throwError({
                        message: 'A network error has occurred.',
                        requestDetails: {
                            status: e.status,
                            statusText: e.statusText,
                            url: e.url,
                            headers: e.headers
                        },
                        error: true,
                        errorType: 'network-error',
                        json: function() { return this; }
                    });
                }

                return throwError(e);
            }),
            map(response => {
                if (response.status === 204)
                    return undefined;

                let result : any = response.body;

                if (result && typeof result === 'object') {
                    if (response.headers.get('X-TYT-Authentication'))
                        result['x-tyt-authentication'] = response.headers.get('X-TYT-Authentication')

                    if (result instanceof Array) {
                        if (response.headers.get('X-Total'))
                            result['total'] = Number(response.headers.get('X-Total'));
                        else if (response.headers.get('X-TYT-Total'))
                            result['total'] = Number(response.headers.get('X-TYT-Total'));
                    }
                }

                return result;
            })
        );
    }

    private applyGlobalHeaders(headers : Record<string,string|string[]>) {
        if (this.configuration.accessToken) {
            let accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers['Authorization'] = 'Bearer ' + accessToken;
        }

        if (this.configuration.globalHeaders) {
            Object.keys(this.configuration.globalHeaders).forEach(key => {
                headers[key] = this.configuration.globalHeaders[key];
            })
        }
    }

}